<template>
  <div>
    <s-dialog :open="true" size="sm" to="stds-dialog-confirm-release-build">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{ $t('studio.prj_prod.this_prod.home_apply_stt2') }}
            </p>
            <p class="mt-4 text-xs leading-xs font-regular text-on-surface-elevation-4">
              {{ $t('studio.common.popup_case_i_release_guide') }}
            </p>
            <div class="mt-8">
              <input-text
                v-model="releaseForm"
                name="releaseForm"
                :placeholder="CONFIRM_TYPE.RELEASE"
                maxLength="6"
                :pattern="REGEX_RELEASE"
                :countable="false"
                :clearable="false"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="onCancle">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            class="w-full"
            variant="primary"
            :disabled="!isReleaseEnabled || !meta.valid"
            @click="onRelease"
          >
            {{ $t('studio.common.popup_case_apply_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="stds-dialog-confirm-release-build" />
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';

import InputText from '@/components/validation/input-text.vue';
import { CONFIRM_TYPE } from '@/constants/common.const';

const { handleSubmit, meta } = useForm({
  initialValues: {
    releaseForm: ''
  }
});

const REGEX_RELEASE = /^.{0,6}$/;

const emits = defineEmits<{
  close: [v: boolean];
}>();

const isReleaseEnabled = ref(false);
const releaseForm = ref('');

watch(
  () => releaseForm.value,
  (newValue: string) => {
    isReleaseEnabled.value = newValue.toUpperCase() === CONFIRM_TYPE.RELEASE;
  }
);

const onCancle = () => {
  emits('close', false);
};

const onRelease = handleSubmit(async () => {
  emits('close', true);
});
</script>
